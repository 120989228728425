<template>
  <div class="view-cloth" v-if="init">
    <ViewClothContent :cloth="cloth"></ViewClothContent>

    <section class="actions">
      <button v-if="!hideEditBtn" class="horizon-btn" @click="editClicked">編輯單品</button>
      <button v-if="!hideDropBtn" class="horizon-btn" :class="{'white': cloth.recycleType === -1}" @click="recycelClicked">{{recycleBtnText}}</button>
      <button v-if="!hideRecoverBtn" class="horizon-btn white" @click="showRecoverConfirm">還原至衣櫥</button>
    </section>

    <transition name="fade">
      <RecycleModal v-if="isRecycleModalShow" :cloth-serials="[cloth.serial]" :hide-option-values="[cloth.recycleType]" @close="closeRecycleModal" @updated="clothRecycled">
      </RecycleModal>
    </transition>

    <transition name="fade">
      <WcCommonModal title="確定還原" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="clothRecover" hide-header>
        <div class="text-center" style="font-size: 14px;">
          確定將此衣物<br/>
          還原至衣櫥？
        </div>
      </WcCommonModal>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import RecycleModal from '@/views/closet/RecycleModal.vue';
import clothDefault from '@/dataModel/cloth.js';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import ViewClothContent from '@/views/closet/ViewClothContent';
import '@/assets/css/buttons.css';

export default {
  name: 'ViewCloth',
  data() {
    return {
      initBusyName: 'VIEWCLOTHINITREADING',
      recoverBusyName: 'CLOTHRECOVERING',
      init: false,
      cloth: clothDefault,
      isRecycleModalShow: false,
      isConfirmModalShow: false,
    };
  },
  components: {
    RecycleModal,
    WcCommonModal,
    ViewClothContent,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    hideEditBtn: {
      type: Boolean,
      default: false,
    },
    hideDropBtn: {
      type: Boolean,
      default: false,
    },
    hideRecoverBtn: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
  },
  mounted() {
    this.appendComponentBusy(this.initBusyName);
    Promise.all([
      this.$store.dispatch('api/readClothInfoPromise', this.id),
    ]).then((data) => {
      const cloth = data[0];
      this.cloth = Object.assign({}, this.cloth, cloth);
      this.init = true;
    }).catch((e) => {
      this.emitError(e);
    }).then(() => {
      this.clearComponentBusy(this.initBusyName);
    });
  },
  computed: {
    recycleBtnText() {
      return this.cloth.recycleType === -1 ? '淘汰單品' : '編輯淘汰方式';
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    emitError(err) {
      this.appendErrorMsg(err);
    },
    editClicked() {
      this.$emit('edit');
    },
    recycelClicked() {
      this.isRecycleModalShow = true;
    },
    closeRecycleModal() {
      this.isRecycleModalShow = false;
    },
    clothRecycled(updateCloths) {
      if (this.cloth.recycleType === -1) {
        this.showMsgModal('已淘汰衣物');
      } else {
        this.showMsgModal('衣物淘汰方式已儲存');
      }
      for (const cloth of updateCloths) {
        this.updateCloth(cloth);
      }
      this.$emit('close');
    },
    showRecoverConfirm() {
      this.isConfirmModalShow = true;
    },
    async clothRecover() {
      this.appendComponentBusy(this.recoverBusyName);
      try {
        const updatedCloth = await this.$store.dispatch('api/recoverClothPromise', this.cloth.serial);
        this.updateCloth(updatedCloth);
        this.showMsgModal(`已還原至衣櫥`);
        this.$emit('close');
      } catch(err) {
        this.emitError(err);
      } finally {
        this.clearComponentBusy(this.recoverBusyName);
      }
    },
  }
}
</script>

<style scoped>
  .create-cloth {
  }

  .imgs {
    position: relative;
  }

  .square-outer {
    position: relative;
    width: 100%;
    background-color: #eeeeee;
    padding-top: 100%;
  }

  .square-outer>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .use-count {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    border-radius: 999px;
    background-color: #fff;
    color: var(--text-dark);
    font-size: 12px;
    padding: 4px 12px;
    box-shadow: 0px 0px 4px #00000040;
  }

  .square-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .square-inner>img {
    width: 46px;
    height: 46px;
    object-fit: contain;
  }

  .square-inner>p {
    margin: 0;
    font-size: 12px;
    color: #fff;
  }

  /* info */
  .info {
    padding: 1rem;
    position: relative;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-wrap: nowrap;
  }

  .info-row.v-center {
    align-items: center;
  }

  .info-col-title {
    flex: 1 1;
    font-size: 14px;
    padding-right: 1rem;
  }

  .info-col-title.sub {
    padding-left: 1rem;
  }

  .info-col-title>label {
    font-weight: 600;
    margin: 0;
  }

  .info-col-title>label.required::after {
    content: '*';
    color: var(--required-orange);
  }

  .info-col-content {
    flex: 0 0 70%;
    max-width: 70%;
    font-size: 12px;
  }

  .pills.brand-pill {
    --pill-color: var(--main-green);
  }

  .pills.tag-pill {
    --pill-color: var(--main-yellow);
  }

  .pills {
    position: relative;
    display: block;
    font-size: 12px;
    color: var(--pill-color);
    border-radius: 999px;
    padding: 3px 14px;
    border: 1px solid var(--pill-color);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .public-pill {
    position: absolute;
    top: .5rem;
    right: 1rem;
    display: block;
    font-size: 12px;
    color: #fff;
    border-radius: 999px;
    padding: 3px 14px;
    border: 1px solid var(--main-green);
    background-color: var(--main-green);
    margin-bottom: .25rem;
    margin-top: .25rem;
  }

  .sub-imgs {
    position: relative;
    display: block;
    /* grid-template-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 4px; */
    overflow-x: auto;
    padding: 4px 0;
  }

  .sub-imgs-inner {
    display: flex;
    flex-wrap: nowrap;
  }

  .sub-imgs-content {
    position: relative;
  }

  .sub-imgs-content:not(:first-child) {
    margin-left: 4px;
  }

  .sub-img-photo {
    display: block;
    position: relative;
    padding-top: 100%;
    width: 100px;
    background-color: #ddd;
    border: solid 2px #ddd;
  }

  .sub-img-photo.active {
    border-color: var(--main-orange);
  }

  .sub-img-photo>img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
  }

</style>
