<template>
    <div class="closet-data-simple">
      <div class="container">
  
        <div class="">
          <p class="title">關於我的身體數據</p>
          
          <div class="mb-2">
            <router-link to="/analyzeHada" class="hada-div">
              <div class="hada-title">
                我的皮膚色調
              </div>
              <div class="hada-content" :class="hadaClass">
                {{ hadaStr }}
              </div>
            </router-link>
          </div>
          <div class="mb-2" @click="showBodyDialog">
            <BodyDataPanel :value="body"></BodyDataPanel>
          </div>
          <div class="">
            <BrandSizeContainer v-model="brandSizeList" @addClick="showBodyDialog" hide-delete show-add></BrandSizeContainer>
          </div>
        </div>
  
      </div>
  
    </div>
  </template>
  
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import BrandSizeContainer from '@/components/body/BrandSizeContainer.vue'
  import BodyDataPanel from '@/components/body/BodyDataPanel.vue'
  
  export default {
    name: 'AnalyzeBody',
    data() {
      return {
        readingBusyName: 'READINGSNIPPESTDATA',
  
        body: {
          buttockSize: 0,
          chestSize: 0,
          feetSize: 0,
          headSize: 0,
          height: 0,
          opp: "",
          shoulderSize: 0,
          waistSize: 0,
          weight: 0,
          hada: 0,
        },
  
        brandSizeList: [],
      };
    },
    components: {
      BrandSizeContainer,
      BodyDataPanel,
    },
    props: {
    },
    beforeDestroy() {
      this.clearComponentBusy(this.readingBusyName);
    },
    async mounted() {
      await this.__refresh();
    },
    computed: {
      ...mapGetters(['isBodyConfigShow']),
      hadaStr() {
        switch(this.body.hada) {
          case 0:
            return '未檢測';
          case 1:
            return '冷色調';
          case 2:
            return '暖色調';
        }
        return '';
      },
      hadaClass() {
        switch(this.body.hada) {
          case 0:
            return 'hada-unknown';
          case 1:
            return 'hada-cold';
          case 2:
            return 'hada-warm';
        }
        return '';
      },
    },
    watch: {
      isBodyConfigShow(val) {
        if (!val) {
          this.__refresh();
        }
      },
    },
    methods: {
      ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
      async __refresh() {
        this.appendComponentBusy(this.readingBusyName);
        try {
          const userBody = await this.$store.dispatch('api/getUserBodyDataPromise');
          this.body = Object.assign({}, this.body, userBody);
          const sizeList = await this.$store.dispatch('api/readBrandSizeListPromise');
          this.brandSizeList.splice(0, this.brandSizeList.length);
          for (const s of sizeList) {
            this.brandSizeList.push(s);
          }
        } catch(err) {
          this.appendErrorMsg(err.toString());
        } finally {
          this.clearComponentBusy(this.readingBusyName);
        }
      },
      showBodyDialog() {
        this.setBodyConfigShow(true);
      },
    }
  }
  </script>
  
  <style scoped>
    .closet-data-simple {
      padding: .5rem 0;
    }
  
    .title {
      position: relative;
      font-weight: 800;
      width: fit-content;
      letter-spacing: 0.05em;
      margin-top: 1rem;
      margin-bottom: .5rem;
      font-size: 1.3rem;
    }
  
    .title>svg {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(1rem, -50%);
    }

    .hada-div {
      /* border: solid 2px #CCC; */
      border-radius: .5rem;
      display: flex;
      /* justify-content: space-between; */
      padding: .8rem 1rem;
      background-color: #EEEEEE;
      align-items: center;
    }

    .hada-title {
      flex: 0 0 40%;
      /* border-right: solid 1px #CCC; */
      border-right: solid 2px #fff;
      margin-right: 1rem;
      color: #444;
      text-align: center;
    }

    .hada-content {
      flex: 1 1;
      background-color: #fff;
      border-radius: .5rem;
      padding: .5rem;
      text-align: center;
      font-weight: 1000;
    }

    .hada-content.hada-unknown {
      color: #CCC;
    }

    .hada-content.hada-cold {
      color: #201C6D;
    }

    .hada-content.hada-warm {
      color: var(--main-orange);
    }
  
  </style>
  