<template>
  <div class="cloth-list-select">
    <section class="py-3 position-relative">
      <p class="text-center mb-0">已選取 {{selectedClothCount}} 項單品</p>
      <a href="#" class="trash-btn" @click.prevent="openDeleteModal">
        <img :src="require('@/assets/icons/Trash.png')"/>
      </a>
    </section>

    <section class="header-tools">
      <div class="container">
        <div class="row">
          <div class="col">
            <a href="#" class="header-tool-btn" @click.prevent="openRecycleModal">
              編輯淘汰方式
            </a>
          </div>

          <div class="col">
            <a href="#" class="header-tool-btn" @click.prevent="openRecoverModal">
              還原至衣櫥
            </a>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <section class="category-list">
        <div class="category-cloths">
          <div class="row justify-content-between align-items-center no-gutters">
            <p class="cs-name">{{categoryName}}</p>
          </div>

          <div class="d-flex align-items-center cloth-count">
            <img :src="require('@/assets/icons/cloth-icon.png')" style="width: 14px; display: block; margin-right: .25rem;"/>
            <span>{{cloths.length}}件</span>
          </div>

          <div class="row">
            <div class="col-4 position-relative mb-3" v-for="(item, index) in parseCloths" :key="item.cloth.serial">
              <div class="" @click="clothClicked(index)">
                <ClothBlock :cloth="item.cloth" showCheck :checked="item.selected">
                </ClothBlock>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>

    <transition name="fade">
      <RecycleModal v-if="isRecycleModalShow" :cloth-serials="selectedClothSerials" :hide-option-values="[categorySerial]" @close="closeRecycleModal" @updated="clothRecycled">
      </RecycleModal>
    </transition>

    <transition name="fade">
      <WcCommonModal v-if="isRecoverModalShow" @cancel="closeRecoverModal" @ok="recoverOkClicked" hide-header>
        <div class="" style="font-size: 14px;">
          <p class="mb-0 text-center">確定還原?</p>
        </div>
      </WcCommonModal>
    </transition>

    <transition name="fade">
      <WcCommonModal v-if="isDeleteModalShow" @cancel="closeDeleteModal" @ok="deleteOkClicked" hide-header>
        <div class="" style="font-size: 14px;">
          <p class="mb-0 text-center">確定刪除?</p>
        </div>
      </WcCommonModal>
    </transition>

  </div>
</template>

<script>
import ClothBlock from '@/components/ClothBlock.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import RecycleModal from '@/views/closet/RecycleModal.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ClothListSelect',
  data() {
    return {
      init: false,
      savingBusyName: 'RECYCLELISTSELECTSAVING',

      isRecycleModalShow: false,
      isRecoverModalShow: false,
      isDeleteModalShow: false,
      parseCloths: [],
    };
  },
  created() {
  },
  props: {
    cloths: {
      type: Array,
      required: true,
    },
    categorySerial: {
      type: Number,
      required: true,
    },
    categoryName: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  components: {
    ClothBlock,
    WcCommonModal,
    RecycleModal,
  },
  computed: {
    selectedClothSerials() {
      return this.parseCloths.reduce((sum, item) => {
        if (item.selected) {
          sum.push(item.cloth.serial);
        }
        return sum;
      }, []);
    },
    selectedClothCount() {
      return this.parseCloths.reduce((sum, item) => {
        return item.selected? sum + 1 : sum;
      }, 0);
    }
	},
  beforeDestroy() {
    this.clearComponentBusy(this.savingBusyName);
  },
  watch: {
  },
  mounted() {
    this.parseData();
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    parseData() {
      this.parseCloths.splice(0, this.parseCloths.length);
      for (const cloth of this.cloths) {
        this.parseCloths.push({
          cloth,
          selected: false,
        });
      }
    },
    clothClicked(index) {
      this.parseCloths[index].selected = !this.parseCloths[index].selected;
    },
    openRecycleModal() {
      if (this.selectedClothCount === 0) {
        this.showMsgModal('請選取至少一項單品。');
        return;
      }
      this.selectRecycleMethod = -1;
      this.isRecycleModalShow = true;
    },
    openRecoverModal() {
      this.isRecoverModalShow = true;
    },
    closeRecycleModal() {
      this.isRecycleModalShow = false;
    },
    closeRecoverModal() {
      this.isRecoverModalShow = false;
    },
    clothRecycled(updatedCloths) {
      this.closeRecycleModal();
      this.showMsgModal(`已編輯 ${this.selectedClothCount} 件單品。`);
      this.$emit('updated', updatedCloths);
    },
    async recoverOkClicked() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        const updatedCloths = [];
        for (const data of this.parseCloths) {
          if (!data.selected) {
            continue;
          }
          const {cloth} = data;
          const updatedCloth = await this.$store.dispatch('api/recoverClothPromise', cloth.serial);
          updatedCloths.push(updatedCloth);
        }
        this.closeRecycleModal();
        this.showMsgModal(`已還原 ${this.selectedClothCount} 件單品。`);
        this.$emit('updated', updatedCloths);
      } catch(err) {
        this.emitError(err);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    openDeleteModal() {
      if (this.selectedClothCount === 0) {
        this.showMsgModal('請選取至少一項單品。');
        return;
      }
      this.isDeleteModalShow = true;
    },
    closeDeleteModal() {
      this.isDeleteModalShow = false;
    },
    async deleteOkClicked() {
      this.appendComponentBusy(this.savingBusyName);
      try {
        const updatedCloths = [];
        for (const data of this.parseCloths) {
          if (!data.selected) {
            continue;
          }
          const {cloth} = data;
          const updatedCloth = await this.$store.dispatch('api/deleteClothPromise', cloth.serial);
          updatedCloths.push(updatedCloth);
        }
        this.closeRecycleModal();
        this.showMsgModal(`已刪除 ${this.selectedClothCount} 件單品。`);
        this.$emit('updated', updatedCloths);
      } catch(err) {
        this.emitError(err);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
    emitError(err) {
      this.appendErrorMsg(err);
    },
  }
}
</script>

<style scoped>
  .cloth-list {
  }

  .trash-btn {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translate(0, -50%);
  }

  .trash-btn>img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .header-tools {
    background-color: #eeeeee;
    padding: 1rem 0;
  }

  a.header-tool-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-size: 12px;
    color: var(--text-dark);
    padding: .5rem;
  }

  a.header-tool-btn:hover {
    text-decoration: none;
  }

  a.header-tool-btn>img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    display: block;
    margin-right: .5rem;
  }

  .category-list {

  }

  a.select-btn {
    font-size: 12px;
    display: block;
    background-color: #eeeeee;
    border-radius: .5rem;
    padding: 2px .5rem;
    color: var(--text-dark);
  }

  a.select-btn:hover {
    text-decoration: none;
  }

  .category-cloths {
    padding: 1.5rem 0 .5rem;
  }

  .category-cloths:not(:first-child) {
    border-top: solid 1px #C4C4C4;
  }

  .cs-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--text-dark);
    margin: 0;
  }

  .cloth-count {
    font-size: 12px;
    color: var(--text-light);
    margin-bottom: .5rem;
  }
</style>
