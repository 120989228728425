<template>
  <WcCommonModal title="選擇淘汰方式" @close="closeRecycleModal" @ok="recycleOkClicked" hide-cancel>
    <div class="">
      <div class="mt-2 mb-3" v-for="(item) in showRecycleOptions" :key="item.value">
        <input type="radio" v-model="selectRecycleMethod" :value="item.value" :id="'recycle-' + item.value"/>
        <label :for="'recycle-' + item.value" class="d-inline-block pl-2 mb-0">{{item.name}}</label>
      </div>
    </div>
  </WcCommonModal>
</template>


<script>
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import { mapActions } from 'vuex';

export default {
  name: 'RecycleModal',
  data() {
    return {
      init: false,
      savingBusyName: 'RECYCLEMODALSAVING',

      selectRecycleMethod: -1,
      recycleMethods: [
        {
          value: 0,
          name: '捐贈',
        },
        {
          value: 1,
          name: '二手販賣/寄賣/轉售',
        },
        {
          value: 2,
          name: '舊衣回收桶/回收',
        },
        {
          value: 3,
          name: '垃圾處理',
        },
        {
          value: 4,
          name: '其他',
        },
      ]
    };
  },
  created() {
  },
  props: {
    clothSerials: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    hideOptionValues: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
  },
  components: {
    WcCommonModal
  },
  computed: {
    showRecycleOptions() {
      return this.recycleMethods.filter((item) => {
        return this.hideOptionValues.indexOf(item.value) === -1;
      });
    },
	},
  beforeDestroy() {
    this.clearComponentBusy(this.savingBusyName);
  },
  watch: {
  },
  mounted() {
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    closeRecycleModal() {
      this.$emit('close');
    },
    async recycleOkClicked() {
      if (this.selectRecycleMethod === -1) {
        this.showMsgModal('請選取淘汰方式');
        return;
      }
      this.appendComponentBusy(this.savingBusyName);
      try {
        const updatedCloths = [];
        for (const serial of this.clothSerials) {
          const updatedCloth = await this.$store.dispatch('api/recycleClothPromise', {
            serial,
            recycleType: this.selectRecycleMethod,
          });
          updatedCloths.push(updatedCloth);
        }
        this.$emit('updated', updatedCloths);
      } catch(err) {
        this.appendErrorMsg(err);
      } finally {
        this.clearComponentBusy(this.savingBusyName);
      }
    },
  }
}
</script>

<style scoped>

</style>
